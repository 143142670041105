/* eslint-disable */
// @ts-nocheck

import React, { useState, useRef, useEffect, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import styled from "styled-components";
import axios from "axios";
import {
    PiChatsCircleBold,
    PiXBold,
    PiPaperPlaneTiltBold,
    PiChatCircleDotsFill,
    PiArrowsInBold,
    PiArrowsOutBold,
    PiSpinnerGapBold
} from "react-icons/pi";
import ReactMarkdown from "react-markdown";
import "./agent.css";
import {useQuery} from "@tanstack/react-query";
import {getCandidateAgentDetails} from "../services/api.ts";
import {Input} from "antd";

// Types
interface Message {
    id: string;
    text: string;
    sender: "user" | "agent";
    timestamp: Date;
}

interface ChatSession {
    sessionId: string;
    messages: Message[];
}

interface Job {
    id: string;
    title: string;
    description: string;
    company: string;
    location: string;
    salary?: string;
    jobCategory: string;
    requirements: string[];
    matchScore?: number;
}

const api = axios.create({
    baseURL: 'https://agent.silvertalent.info',
});

const chatApi = {
    startSession: async (userProfile: any) => {
        try {
            const response = await api.post(`/chat/start`, {
                userProfile
            });
            return response.data;
        } catch (error) {
            console.error("Failed to start chat session:", error);
            throw error;
        }
    },

    sendMessage: async (sessionId: string, message: string) => {
        try {
            const response = await api.post(
                `/chat/session/${sessionId}/message`,
                { message }
            );
            return response.data;
        } catch (error) {
            console.error("Failed to send message:", error);
            throw error;
        }
    },

    getJobRecommendations: async (sessionId: string) => {
        try {
            const response = await api.get(
                `/chat/session/${sessionId}/recommendations`
            );
            return response.data.recommendations as Job[];
        } catch (error) {
            console.error("Failed to get job recommendations:", error);
            throw error;
        }
    },

    getTrainingSuggestions: async (sessionId: string) => {
        try {
            const response = await api.get(
                `/chat/session/${sessionId}/training-suggestions`
            );
            return response.data.suggestions;
        } catch (error) {
            console.error("Failed to get training suggestions:", error);
            throw error;
        }
    }
};


export const SilverAgent: React.FC = () => {
    const { user } = useContext(AuthContext);
    const [isOpen, setIsOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [message, setMessage] = useState("");
    const [chatSession, setChatSession] = useState<ChatSession | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [jobRecommendations, setJobRecommendations] = useState<Job[]>([]);
    const [trainingSuggestions, setTrainingSuggestions] = useState<string | null>(null);
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const messagesContainerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const {data: agentDetails} = useQuery({
        queryKey: ['candidateAgentDetails', user?._candidate!.id],
        queryFn: ()=>getCandidateAgentDetails(user!._candidate!.id),
    });
    // Scroll to bottom when new messages are added
    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [chatSession?.messages, jobRecommendations, trainingSuggestions]);

    // Focus input when chat is opened
    useEffect(() => {
        if (isOpen && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isOpen]);

    // Focus input when loading completes
    useEffect(() => {
        if (!isLoading && isOpen && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isLoading, isOpen]);

    const toggleChat = async () => {
        if (!isOpen && !chatSession) {
            // Start a new session when the chat is opened for the first time
            await startSession();
        }
        setIsOpen(!isOpen);
    };

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const startSession = async () => {
        try {
            setIsLoading(true);
            setError(null);

            // Call the API to start a new chat session
            const { sessionId, initialResponse } = await chatApi.startSession(agentDetails);

            // Create a new chat session with the initial welcome message
            setChatSession({
                sessionId,
                messages: [
                    {
                        id: Date.now().toString(),
                        text: initialResponse,
                        sender: "agent",
                        timestamp: new Date(),
                    },
                ],
            });
        } catch (error) {
            setError("Failed to start the conversation. Please try again later.");
            console.error("Failed to start chat session:", error);
        } finally {
            setIsLoading(false);
            // Focus input after session starts
            setTimeout(() => {
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            }, 100);
        }
    };

    const startNewSession = async () => {
        // Clear current session
        setJobRecommendations([]);
        setTrainingSuggestions(null);

        // Start new session
        await startSession();
    };

    const fetchJobRecommendations = async (sessionId: string) => {
        try {
            const recommendations = await chatApi.getJobRecommendations(sessionId);
            setJobRecommendations(recommendations);
        } catch (error) {
            console.error("Failed to get job recommendations:", error);
        }
    };

    const fetchTrainingSuggestions = async (sessionId: string) => {
        try {
            const suggestions = await chatApi.getTrainingSuggestions(sessionId);
            setTrainingSuggestions(suggestions);
        } catch (error) {
            console.error("Failed to get training suggestions:", error);
        }
    };

    const sendMessage = async () => {
        if (!message.trim() || !chatSession) return;

        const messageText = message.trim();
        setMessage("");
        setError(null);

        // Add user message to the chat
        const updatedMessages = [
            ...(chatSession.messages || []),
            {
                id: Date.now().toString(),
                text: messageText,
                sender: "user",
                timestamp: new Date(),
            },
        ];

        setChatSession({
            ...chatSession,
            messages: updatedMessages,
        });

        setIsLoading(true);

        try {
            const response = await chatApi.sendMessage(chatSession.sessionId, messageText);

            setChatSession({
                ...chatSession,
                messages: [
                    ...updatedMessages,
                    {
                        id: Date.now().toString(),
                        text: response.response,
                        sender: "agent",
                        timestamp: new Date(),
                    },
                ],
            });
        } catch (error) {
            console.error("Failed to send message:", error);

            if (axios.isAxiosError(error) && error.response?.status === 404) {
                setError("Your session has expired. Please refresh the page to start a new conversation.");
            } else {
                setError("Failed to send your message. Please try again.");

                // Add error message
                setChatSession({
                    ...chatSession,
                    messages: [
                        ...updatedMessages,
                        {
                            id: Date.now().toString(),
                            text: "Sorry, there was an error processing your message. Please try again.",
                            sender: "agent",
                            timestamp: new Date(),
                        },
                    ],
                });
            }
        } finally {
            setIsLoading(false);
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            sendMessage();
        }
    };

    const renderJobRecommendations = () => {
        if (jobRecommendations.length === 0) return null;

        return (
            <RecommendationsContainer>
                <RecommendationTitle>
                    <PiChatCircleDotsFill size={18} /> Job Recommendations
                </RecommendationTitle>
                {jobRecommendations.slice(0, 3).map((job) => (
                    <RecommendationItem key={job.id}>
                        <strong>{job.title}</strong>
                        <div>
                            {job.company} • {job.location}
                            {job.matchScore && <span className="match-score">{job.matchScore}% match</span>}
                        </div>
                    </RecommendationItem>
                ))}
            </RecommendationsContainer>
        );
    };

    const renderTrainingSuggestions = () => {
        if (!trainingSuggestions) return null;

        return (
            <RecommendationsContainer>
                <RecommendationTitle>
                    <PiChatCircleDotsFill size={18} /> Training Suggestions
                </RecommendationTitle>
                <RecommendationItem>
                    <ReactMarkdown>{trainingSuggestions}</ReactMarkdown>
                </RecommendationItem>
            </RecommendationsContainer>
        );
    };

    const renderMessageContent = (text: string) => {
        return <ReactMarkdown>{text}</ReactMarkdown>;
    };

    return (
        <>
            <ChatIconContainer onClick={toggleChat}>
                <IconWrapper isOpen={isOpen} className="chat-icon">
                    <PiChatsCircleBold size={28} />
                </IconWrapper>
                <IconWrapper isOpen={isOpen} className="close-icon">
                    <PiXBold size={28} />
                </IconWrapper>
            </ChatIconContainer>

            <ChatContainer isOpen={isOpen} isExpanded={isExpanded}>
                <ChatHeader>
                    <ChatHeaderLeft>
                        <AgentIcon>
                            <PiChatsCircleBold />
                        </AgentIcon>
                        <ChatTitle>SilverAgent</ChatTitle>
                    </ChatHeaderLeft>

                    <HeaderActions>
                        <NewChatButton onClick={startNewSession}>
                            <PiChatCircleDotsFill size={18} /> New Chat
                        </NewChatButton>
                        <IconButton onClick={toggleExpand} title={isExpanded ? "Collapse" : "Expand"}>
                            {isExpanded ? <PiArrowsInBold size={18} /> : <PiArrowsOutBold size={18} />}
                        </IconButton>
                        <IconButton onClick={() => setIsOpen(false)} title="Close">
                            <PiXBold size={18} />
                        </IconButton>
                    </HeaderActions>
                </ChatHeader>

                <MessagesContainer ref={messagesContainerRef}>
                    {chatSession?.messages.map((msg) => (
                        <MessageWrapper key={msg.id} sender={msg.sender}>
                            <MessageBubble sender={msg.sender}>
                                {renderMessageContent(msg.text)}
                            </MessageBubble>
                        </MessageWrapper>
                    ))}

                    {renderJobRecommendations()}
                    {renderTrainingSuggestions()}

                    {error && (
                        <MessageWrapper sender="agent">
                            <ErrorMessage sender="agent">
                                {error}
                            </ErrorMessage>
                        </MessageWrapper>
                    )}

                    {isLoading && (
                        <TypingIndicator>
                            <PiSpinnerGapBold size={16} className="spinner" /> SilverAgent is typing...
                        </TypingIndicator>
                    )}

                    <div ref={messagesEndRef} />
                </MessagesContainer>

                <InputArea>
                    <MessageInput
                        type="text"
                        placeholder="Type a message..."
                        value={message}
                        autoFocus={true}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyPress={handleKeyPress}
                        disabled={isLoading}
                        ref={inputRef}
                    />
                    <SendButton onClick={sendMessage} disabled={!message.trim() || isLoading}>
                        <PiPaperPlaneTiltBold size={22} />
                    </SendButton>
                </InputArea>
            </ChatContainer>
        </>
    );
};


// Styled Components with improved design
const ChatIconContainer = styled.button`
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: linear-gradient(135deg, #00a9b2 0%, #0075b2 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    overflow: hidden;
    z-index: 1000;

    &:hover {
        transform: scale(1.05);
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25);
    }
`;

const IconWrapper = styled.div<{ isOpen: boolean }>`
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    color: white;
    
    &.chat-icon {
        opacity: ${({ isOpen }) => (isOpen ? '0' : '1')};
        transform: ${({ isOpen }) => (isOpen ? 'rotate(-180deg)' : 'rotate(0)')};
    }
    
    &.close-icon {
        opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
        transform: ${({ isOpen }) => (isOpen ? 'rotate(0)' : 'rotate(180deg)')};
    }
`;

const ChatContainer = styled.div<{ isOpen: boolean; isExpanded: boolean }>`
    position: fixed;
    bottom: 90px;
    right: 20px;
    width: ${({ isExpanded }) => (isExpanded ? '70vw' : '400px')};
    height: ${({ isExpanded }) => (isExpanded ? '80vh' : '600px')};
    border-radius: 16px;
    background-color: white;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    z-index: 1000;
    overflow: hidden;
    transition: all 0.3s ease;
    opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
    transform: ${({ isOpen }) => (isOpen ? 'translateY(0) scale(1)' : 'translateY(20px) scale(0.95)')};
    transform-origin: bottom right;
    visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
`;

const ChatHeader = styled.div`
    background: linear-gradient(135deg, #00a9b2 0%, #0075b2 100%);
    color: white;
    padding: 16px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const ChatHeaderLeft = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

const AgentIcon = styled.div`
    background-color: rgba(255, 255, 255, 0.2);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
`;

const ChatTitle = styled.h3`
    margin: 0;
    font-size: 18px;
    font-weight: 600;
`;

const HeaderActions = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

const IconButton = styled.button`
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 4px 8px;
    border-radius: 6px;
    transition: all 0.2s ease;

    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }
`;

const NewChatButton = styled(IconButton)`
    font-size: 14px;
    font-weight: 500;
`;

const MessagesContainer = styled.div`
    flex: 1;
    padding: 16px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
    scroll-behavior: smooth;
`;

const MessageWrapper = styled.div<{ sender: "user" | "agent" }>`
    display: flex;
    justify-content: ${({ sender }) => (sender === "user" ? "flex-end" : "flex-start")};
    opacity: 0;
    transform: translateY(10px);
    animation: messageAppear 0.3s forwards;
    
    @keyframes messageAppear {
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
`;

const MessageBubble = styled.div<{ sender: "user" | "agent" }>`
    max-width: 80%;
    padding: 14px 18px;
    border-radius: ${({ sender }) =>
    sender === "user"
        ? "18px 18px 4px 18px"
        : "18px 18px 18px 4px"};
    font-size: 15px;
    background-color: ${({ sender }) =>
    sender === "user" ? "#00a9b2" : "#F3F4F6"};
    color: ${({ sender }) => (sender === "user" ? "white" : "#1F2937")};
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
    word-break: break-word;
`;

const TypingIndicator = styled.div`
    align-self: flex-start;
    padding: 8px 16px;
    background-color: #F3F4F6;
    border-radius: 18px;
    font-size: 14px;
    color: #6B7280;
    display: flex;
    align-items: center;
    gap: 8px;
    animation: pulse 1.5s infinite;
    
    @keyframes pulse {
        0%, 100% { opacity: 1; }
        50% { opacity: 0.5; }
    }
`;

const RecommendationsContainer = styled.div`
    margin-top: 4px;
    padding: 14px 16px;
    background-color: #F9FAFB;
    border-radius: 12px;
    border-left: 4px solid #4F46E5;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    animation: slideIn 0.5s forwards;
    
    @keyframes slideIn {
        from {
            opacity: 0;
            transform: translateY(10px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
`;

const RecommendationTitle = styled.div`
    font-weight: 600;
    margin-bottom: 10px;
    color: #4F46E5;
    display: flex;
    align-items: center;
    gap: 6px;
`;

const RecommendationItem = styled.div`
    margin-bottom: 8px;
    padding: 8px 10px;
    background-color: white;
    border-radius: 8px;
    font-size: 14px;
    border: 1px solid #E5E7EB;
    transition: all 0.2s ease;
    cursor: pointer;
    
    &:hover {
        border-color: #4F46E5;
        background-color: #F5F5FF;
    }
    
    strong {
        color: #4F46E5;
    }
`;

const InputArea = styled.div`
    display: flex;
    padding: 16px;
    border-top: 1px solid #E5E7EB;
    background-color: white;
`;

const MessageInput = styled(Input)`
    flex: 1;
    padding: 14px 18px;
    border: 1px solid #D1D5DB;
    border-radius: 24px;
    outline: none;
    font-size: 15px;
    background-color: #F9FAFB;
    transition: all 0.2s ease;

    &:focus {
        border-color: #00a9b2;
        box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.2);
        background-color: white;
    }
    
    &:disabled {
        background-color: #F3F4F6;
        cursor: not-allowed;
    }
`;

const SendButton = styled.button`
    background-color: #00a9b2;
    color: white;
    border: none;
    border-radius: 50%;
    width: 46px;
    height: 46px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: 0 2px 6px rgba(79, 70, 229, 0.3);
  
    &:hover {
        background-color: #0075b2;
        transform: translateY(-2px);
    }
  
    &:active {
        transform: translateY(0);
    }
  
    &:disabled {
        background-color: #9CA3AF;
        cursor: not-allowed;
        transform: translateY(0);
        box-shadow: none;
    }
`;

const ErrorMessage = styled(MessageBubble)`
    background-color: #FEE2E2;
    color: #B91C1C;
    border-left: 3px solid #B91C1C;
`;
