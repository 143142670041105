import {ReactNode, useEffect, useRef} from "react";
import {ICandidate, IJobDetails, IMessage} from "../../services/types.ts";

export const TableRow = ({
                             index,
                             setSize,
                             flattenedData,
                             children
                         }: {
    index: number;
    setSize: (index: number, size: number) => void;
    flattenedData: ICandidate[] | IJobDetails[] | IMessage[];
    children: (item: ICandidate | IJobDetails | IMessage) => ReactNode;
}) => {
    const item = flattenedData[index];
    const rowRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (rowRef.current) {
            const height = rowRef.current.getBoundingClientRect().height;
            setSize(index, height);
        }
    }, [index, setSize]);

    if (!item) {
        return <div></div>;
    }

    return (
        <div ref={rowRef}>
            {children(item)}
        </div>
    );
};
