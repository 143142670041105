/* eslint-disable */
// @ts-nocheck


import styled from "styled-components";
import heroimg from "../../assets/f9f2a372-56c6-4f0f-b729-6191e54ef87e.png";
import bgimg from "../../assets/bg.jpg";
import {useNavigate} from 'react-router-dom';
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {getAvailableCourses, getUserCourses} from "../../services/api.ts";
import {UserCourses} from "./types.ts";
import {ICourse} from "../../services/types.ts";
import {Button} from "antd";
import {format} from "date-fns";
import { de } from 'date-fns/locale';
import {CalendarDots, MapPin} from "@phosphor-icons/react";

export const Courses = () => {
    const navigate = useNavigate();
    const {data: userCourses}: UseQueryResult<UserCourses[], Error> = useQuery({
        queryKey: ['userCourses'],
        queryFn: getUserCourses,
    });

    const {data: courses}: UseQueryResult<ICourse[], Error> = useQuery({
        queryKey: ['courses'],
        queryFn: getAvailableCourses,
    });

    const sortedCourses = courses?.sort((a, b) => {
        if (a.status === "planned" && b.status !== "planned") return 1;
        if (a.status !== "planned" && b.status === "planned") return -1;

        if (a.status !== "planned" && b.status !== "planned") {
            const dateA = new Date(a.start_date);
            const dateB = new Date(b.start_date);
            return dateA.getTime() - dateB.getTime(); // Ascending order by date
        }

        return 0;
    });


    return <Wrapper>
        <Hero>
            <HeroContent>
                <WelcomeText>Wilkommen bei der silveracademy</WelcomeText>
                <WelcomeMeta style={{width: 500}}>Live-Onlinekurse, um Ihre Fähigkeiten für das <br/> Arbeiten im Ruhestand zu stärken
                    und den <br/> Alltag leichter und schöner zu gestalten.</WelcomeMeta>
            </HeroContent>
            <div>
                <img src={heroimg as string} alt="" width={300}/>
            </div>
        </Hero>

        <Steps>
            <Step>
                <h3>(1) Kurs buchen</h3>
                <p>Kurs wählen oder auf die Warteliste setzen.</p>
            </Step>
            <Step>
                <h3>(2) Link erhalten</h3>
                <p>Link per E-Mail bekommen.</p>
            </Step>
            <Step>
                <h3>(3) Einloggen & teilnehmen</h3>
                <p>Bequem von zu Hause lernen.</p>
            </Step>
        </Steps>

        <CourseGrid>
            {sortedCourses?.map((course: ICourse, index) => {
                return <CourseCard key={course.id} onClick={() => navigate(`/courses/${course.id}`)}
                                   style={{cursor: "pointer"}}>
                    <ImageWrapper>
                        <CourseCover src={`https://xmfg-jflm-x0lm.f2.xano.io/${course.course_image}`} alt=""/>
                        <LecturerAvatar width={100} src={`https://xmfg-jflm-x0lm.f2.xano.io/${course.lecturer_avatar}`}
                                        alt=""/>
                    </ImageWrapper>
                    <CourseDetails>
                        <h3>{course.title}: {course.secondary_title}</h3>
                        <CourseMeta>
                            <div>
                                <div>
                                    <MetaInfo><CalendarDots
                                        size={22}/>
                                        {course.status === "planned" ? <p style={{margin: 0}}>In Kürze verfügbar</p> :
                                            <>
                                                {format(new Date(course.start_date), 'dd. MMMM', { locale: de })+", "+format(new Date(course.start_date), 'HH:mm')} Uhr
                                            </>
                                        }
                                    </MetaInfo>
                                </div>
                                <MetaInfo><MapPin
                                    size={22}/>{course.venue}</MetaInfo>
                            </div>
                            <div>
                                {userCourses?.find((el: UserCourses) => el.course_id === course.id) ? (
                                    <p style={{margin: 0}}>Bereits registriert</p>
                                ) : (
                                    <Button type="primary">
                                        Details
                                    </Button>
                                )}
                            </div>
                        </CourseMeta>
                    </CourseDetails>
                </CourseCard>

            })}
        </CourseGrid>
    </Wrapper>
}

const Wrapper = styled.div`
    margin: -8px -100px 0;
    @media (max-width: 1024px) {
        margin: -8px -8px 0;
    }
`;

const Hero = styled.div`
    overflow: hidden;
    background-image: url(${bgimg});
    background-size: cover;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 120px;
    height: 300px;
    color: white;
    @media (max-width: 1024px) {
        height: 350px;
        flex-direction: column;
        gap: 20px;
        img {
            width: 150px;
        }
    }
`;

const HeroContent = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h2 {
        text-align: center;
        font-weight: 300;
        line-height: 1.5;
    }
`;

const Steps = styled.div`
    display: flex;
    justify-content: space-around;
    padding: 20px;
    background-color: #f5f5f5;
    margin-bottom: 40px;
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        padding: 0;
        margin-bottom: 8px;
    }
`;

const Step = styled.div`
    text-align: center;
    flex: 1;
    padding: 20px;

    h3 {
        color: #363360;
        margin-bottom: 10px;
    }

    p {
        color: #666;
    }
    @media (max-width: 768px) {
        padding: 8px;
    }
`;

const CourseGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    padding: 20px;
    @media (max-width: 1024px) {
        grid-template-columns: repeat(2, 1fr);
    }
    // if screen width is getting mobile it should be responsive
    @media (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
    }

`;

const CourseCard = styled.div`
    background: #f8f9fb; /* fallback for old browsers */
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: relative;
    box-shadow: 0px 0px 22px -10px rgba(0, 0, 0, 0.75);
    height: 300px;
    transition: 0.2s ease-in-out;

    &:hover {
        background-color: #f0f4ff;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
        transform: translateY(-2px) scale(1.02);
        cursor: pointer;
    }

    @media (max-width: 1024px) {
        height: 350px;
    }
`;


const CourseCover = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
`;

const LecturerAvatar = styled.img`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    position: absolute;
    bottom: -40px;
    left: 40px;
    border: 4px solid white;
`;

const ImageWrapper = styled.div`
    position: relative;
    height: 50%;
`;
const CourseMeta = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    min-height: 60px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
`;

const CourseDetails = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
    margin: 16px 0 0 0;
    padding: 16px;
    height: 100%;
    min-height: 130px;
    position: relative;
    
    h3 {
        margin: 0 !important;
    }
`;
const WelcomeText = styled.p`
    font-size: 40px;
    margin: 0;
    @media (max-width: 768px) {
        font-size: 22px;
    }
`;

const MetaInfo = styled.p`
    display: flex;
    gap: 8px;
    align-items: center;
    margin: 0 !important;
`;

const WelcomeMeta = styled.h2`
    width: 500px;
    @media (max-width: 768px) {
        width: 100%;
        font-size: 16px;
    }
`;
