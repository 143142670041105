import {Route, Routes, useLocation} from 'react-router-dom'
import {PrivateRoute} from "./ProtectedRoute.tsx";
import {PublicRoute} from "./PublicRoute.tsx";
import {Login} from "../pages/login/Login.tsx";
import {JobPool} from "../pages/jobPool/JobPool.tsx";
import {MyProfile} from "../pages/profile/MyProfile.tsx";
import {Messages} from "../pages/messages/Messages.tsx";
import {CandidateMsgDetails} from "../pages/candidateMsgDetails/CandidateMsgDetails.tsx";
import {Account} from "../pages/account/Account.tsx";
import {Recommendation} from "../pages/recomentation/Recomendation.tsx";
import {Templates} from "../pages/templates/Templates.tsx";
import {Privacy} from "../pages/privacy/Privacy.tsx";
import {Searches} from "../pages/searches/Searches.tsx";
import {EditSearch} from "../pages/editSearch/EditSearch.tsx";
import {JobDetails} from "../pages/jobDetails/JobDetails.tsx";
import {MessageDetail} from "../pages/messageDetail/MessageDetail.tsx";
import {Personal} from "../pages/profile/subPages/Personal.tsx";
import {DesiredJobs} from "../pages/profile/subPages/DesiredJobs.tsx";
import {Location} from "../pages/profile/subPages/Location.tsx";
import {Education} from "../pages/profile/subPages/Education.tsx";
import {WorkHistory} from "../pages/profile/subPages/WorkHistory.tsx";
import {DeleteProfile} from "../pages/deleteProfile/DeleteProfile.tsx";
import {MagicLinkRedirect} from "../pages/magicLink/MagicLinkRedirect.tsx";
import {ErrorState} from "../components/ErrorState.tsx";
import {Courses} from "../pages/courses/Courses.tsx";
import {CourseDetails} from "../pages/courseDetails/CourseDetails.tsx";


export const AppRouter = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const param2 = searchParams.get('param2');
    const target = searchParams.get('target');
    const id = searchParams.get('id');

    return (
        <Routes>
            {(param2 || id) && target ? (
                <Route path="*" element={<MagicLinkRedirect/>}/>
            ) : (
                <>
                    <Route
                        path={"/"}
                        element={
                            <PrivateRoute redirectTo={"/login"}>
                                <JobPool/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/redirects/:type"
                        element={<MagicLinkRedirect/>}
                    />

                    <Route
                        path={"/login"}
                        element={
                            <PublicRoute>
                                <Login/>
                            </PublicRoute>
                        }
                    />
                    <Route
                        path="/profile"
                        element={
                            <PrivateRoute>
                                <MyProfile/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/jobs"
                        element={
                            <PrivateRoute>
                                <JobPool/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/jobs/:id"
                        element={
                            <PrivateRoute>
                                <JobDetails/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/messages"
                        element={
                            <PrivateRoute>
                                <Messages/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/messages/:id"
                        element={
                            <PrivateRoute>
                                <MessageDetail/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/details/:id"
                        element={
                            <PrivateRoute>
                                <CandidateMsgDetails/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/account"
                        element={
                            <PrivateRoute>
                                <Account/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/recomendation"
                        element={
                            <PrivateRoute>
                                <Recommendation/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/templates"
                        element={
                            <PrivateRoute>
                                <Templates/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/privacy"
                        element={
                            <PrivateRoute>
                                <Privacy/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/searches"
                        element={
                            <PrivateRoute>
                                <Searches/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/searches/:id"
                        element={
                            <PrivateRoute>
                                <EditSearch/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/personal"
                        element={
                            <PrivateRoute>
                                <Personal/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/desired-jobs"
                        element={
                            <PrivateRoute>
                                <DesiredJobs/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/location"
                        element={
                            <PrivateRoute>
                                <Location/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/education"
                        element={
                            <PrivateRoute>
                                <Education/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/work-history"
                        element={
                            <PrivateRoute>
                                <WorkHistory/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/delete-profile"
                        element={
                            <PrivateRoute>
                                <DeleteProfile/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/courses"
                        element={
                            <PrivateRoute>
                                <Courses/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/courses/:key"
                        element={
                            <PrivateRoute>
                                <CourseDetails/>
                            </PrivateRoute>
                        }
                    />
                    <Route path="*" element={<ErrorState/>}/>
                </>
            )}
        </Routes>
    )
}

