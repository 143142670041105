import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import * as Sentry from "@sentry/react";


Sentry.init({
    dsn: "https://b0454a854da7294311bacab2932bb631@o4508335801303040.ingest.de.sentry.io/4509063491551312",
    integrations: [Sentry.browserTracingIntegration()],
    environment: import.meta.env.VITE_ENVIRONMENT,
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost", /^https?:\/\/.*\.xano\.io\/api/],
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
