import axios, {AxiosInstance, AxiosResponse, CreateAxiosDefaults, RawAxiosRequestHeaders} from 'axios'

import {setupInterceptors} from './interceptors'
import {
    CandidateCountParams,
    CandidatePoolParams,
    CompanySizeType,
    DeepPartialCandidateProfile,
    DesJobType,
    IAlertConfig,
    ICandidate,
    ICourse,
    IJobDemand,
    IJobDetails,
    ILocation,
    IMessage,
    IMessageBuble,
    IPosition,
    IPositions,
    ISearchResult,
    JobExpType,
    JobTypeOption,
    Locations,
    MatchingPositionsType,
    OutreachRequest,
    OutreachResponse,
    OutreachTemplate,
    OutreachTemplateRequest,
    OutreachTemplateResponse,
    Radius,
    SectorType
} from "./types.ts";
import {User} from "../context/AuthContext.tsx";
import {getToken} from "../util/tokenCookies.ts";
import {notification} from "antd";

const getHeaders = (): RawAxiosRequestHeaders => {
    const headers: RawAxiosRequestHeaders = {};
    if (import.meta.env.VITE_ENVIRONMENT !== 'production') {
        headers["X-Data-Source"] = "staging";
    }

    return headers;
};

const authConfig: CreateAxiosDefaults = {
    baseURL: import.meta.env.VITE_AUTH_URL as string,
    headers: getHeaders()
};

const baseConfig: CreateAxiosDefaults = {
    baseURL: import.meta.env.VITE_BASE_URL as string,
    headers: getHeaders()
};

const billingConfig: CreateAxiosDefaults = {
    baseURL: import.meta.env.VITE_BILLING_URL as string,
    headers: getHeaders()
};

const privacyConfig: CreateAxiosDefaults = {
    baseURL: import.meta.env.VITE_PRIVACY_URL as string,
    headers: getHeaders()
};
const coursesConfig: CreateAxiosDefaults = {
    baseURL: import.meta.env.VITE_COURSES_BASE_URL as string,
    headers: getHeaders()
};

export const authApi: AxiosInstance = setupInterceptors(axios.create(authConfig))
export const baseApi: AxiosInstance = setupInterceptors(axios.create(baseConfig))
export const billingApi: AxiosInstance = setupInterceptors(axios.create(billingConfig))
export const privacyApi: AxiosInstance = setupInterceptors(axios.create(privacyConfig))
export const coursesApi: AxiosInstance = setupInterceptors(axios.create(coursesConfig))

export const getUserApi = async () => {
    const {data} = await authApi.get('/auth/me')
    return data
}

export const loginUserApi = async (email: string, password: string) => {
    try {
        const {data} = await authApi.post('/auth/login', {email, password});
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const errorMessage = error.response?.data?.message || 'Bei der Anmeldung ist ein Fehler aufgetreten';

            notification.error({
                message: 'Anmeldung fehlgeschlagen',
                description: errorMessage,
            });
        } else {
            notification.error({
                message: 'Anmeldung fehlgeschlagen',
                description: 'Bei der Anmeldung ist ein Fehler aufgetreten',
            });
        }
        throw error;
    }
};

export const changeUserEmailApi = async (newEmail: string, currentUserData: User) => {
    const updatedUserData = {
        ...currentUserData,
        email: newEmail,
    } as User;

    const {data}: { data: User } = await authApi.put(`/users/${updatedUserData.id}`, updatedUserData);
    return data;
};

export const signupUserApi = async (email: string, password: string, telephone: string) => {
    const {data} = await authApi.post('/auth/signup', {
        email,
        password,
        role: "client",
        sessionId: telephone,
        pd_person_id: "",
        pd_deal_id: ""
    })
    return data
}

export const getJobPool = async ({limit, offset, positionId, locationId}: {
    limit: number,
    offset: number,
    positionId?: number,
    locationId?: number
}) => {
    const params: CandidatePoolParams = {
        "mode": 'lean',
        limit,
        offset,
    };

    if (positionId) {
        params.search_what_id = positionId;
    }

    if (locationId) {
        params.search_location_id = locationId;
    }

    const {data}: { data: IJobDetails[] } = await baseApi.get('/job_demand', {params});
    return data;
};

export const getJobPoolCount = async (position?: IPosition, location?: ILocation) => {
    const params: CandidateCountParams = {
        mode: "count",
    };
    if (position) {
        params.search_what_id = position.id;
    }

    if (location) {
        params.search_location_id = location.id;
    }

    const {data}: { data: { id: number } } = await baseApi.get('/job_demand', {
        params
    });
    return data;
};

export const getSearchWhat = async (): Promise<IPositions> => {
    const {data}: { data: IPositions } = await baseApi.get('/search_what', {
        params: {mode: "modal"}
    });
    return data;
};

export const getSearchLocation = async (location: string): Promise<Locations> => {
    const {data}: { data: Locations } = await baseApi.get('/search_location', {
        params: {mode: "autocomplete", search: location}
    });
    return data;
};

export const addJobDemand = async (jobDemand: IJobDemand | Partial<IJobDemand>) => {
    const {data} = await baseApi.post('/job_demand', jobDemand);
    return data;
};

export const getAllOutreachTemplates = async (): Promise<OutreachTemplate[]> => {
    try {
        const {data}: { data: OutreachTemplate[] } = await baseApi.get('/outreach_template');
        return data;
    } catch (error) {
        console.error('Error fetching outreach templates:', error);
        throw error;
    }
};

export const getJobDemand = async (params: { mode: string }): Promise<{ id: number, title: string }[]> => {
    const {data}: { data: { id: number, title: string }[] } = await baseApi.get('/job_demand', {params});
    return data;
};

export const getUserDetails = async (userId: string): Promise<Partial<User>> => {
    const {data}: { data: Partial<User> } = await authApi.get(`/users/${userId}`);
    return data;
};

export const getCandidateDetails = async (): Promise<ICandidate[]> => {
    const {data} = await authApi.get(`/candidate`);
    return data[0];
};

export const getCandidateAgentDetails = async (id: string): Promise<ICandidate> => {
    const {data}:{data: ICandidate} = await authApi.get(`/candidate/${id}/agent`);
    return data;
};

export const addOutreachTemplate = async (template: OutreachTemplateRequest): Promise<OutreachTemplateResponse> => {
    try {
        const {data}: AxiosResponse<OutreachTemplateResponse> = await baseApi.post('/outreach_template', template);
        return data;
    } catch (error) {
        console.error('Error adding outreach template:', error);
        throw error;
    }
};

export const addOutreach = async (outreach: OutreachRequest): Promise<OutreachResponse> => {
    try {
        const {data}: AxiosResponse<OutreachResponse> = await baseApi.post('/application', outreach);
        return data;
    } catch (error) {
        console.error('Error adding outreach:', error);
        throw error;
    }
};

export const updateOutreachTemplate = async (template: OutreachTemplateRequest): Promise<OutreachTemplateResponse> => {
    try {
        const {data}: AxiosResponse<OutreachTemplateResponse> = await baseApi.post(`/outreach_template/${template.id}`, template);
        return data;
    } catch (error) {
        console.error('Error updating outreach template:', error);
        throw error;
    }
};

export const deleteOutreachTemplate = async (id: number): Promise<void> => {
    try {
        await baseApi.delete(`/outreach_template/${id}`);
    } catch (error) {
        console.error('Error deleting outreach template:', error);
        throw error;
    }
};


export const getJobTypes = async (): Promise<JobTypeOption[]> => {
    const {data}: { data: JobTypeOption[] } = await baseApi.get<JobTypeOption[]>('/job_type');
    return data;
};

export const getHomeofficeOptions = async (): Promise<JobTypeOption[]> => {
    const {data}: { data: JobTypeOption[] } = await baseApi.get<JobTypeOption[]>('/homeoffice');
    return data;
};

export const getWorkloadOptions = async (): Promise<JobTypeOption[]> => {
    const {data}: { data: JobTypeOption[] } = await baseApi.get<JobTypeOption[]>('/work_load');
    return data;
};

export const getTargetGroupOptions = async (): Promise<JobTypeOption[]> => {
    const {data}: { data: JobTypeOption[] } = await baseApi.get<JobTypeOption[]>('/target_group');
    return data;
};

export const getJobs = async ({limit, offset}: { limit: number, offset: number }): Promise<IJobDetails[]> => {
    const {data}: AxiosResponse<IJobDetails[]> = await baseApi.get<IJobDetails[]>('/job_demand', {
        params: {
            limit,
            offset
        }
    });
    return data;
};

export const getJobById = async (id: string): Promise<IJobDetails> => {
    const {data}: AxiosResponse<IJobDetails> = await baseApi.get<IJobDetails>(`/job_demand/${id}`);
    return data;
};

export const getJobByKey = async (key: string): Promise<IJobDetails[]> => {
    const params = {mode: "key", key: key}
    const {data}: AxiosResponse<IJobDetails[]> = await baseApi.get<IJobDetails[]>(`/job_demand`, {params});
    return data;
};

export const getMessagesByKey = async (key: string): Promise<IJobDetails[]> => {
    const params = {mode: "key", key: key}
    const {data}: AxiosResponse<IJobDetails[]> = await baseApi.get<IJobDetails[]>(`/communication`, {params});
    return data;
};

export const deleteJobDemand = async (id: string): Promise<void> => {
    await baseApi.delete(`/job_search/${id}`);
};

export const getMessages = async ({limit, offset}: { limit: number, offset: number }): Promise<IMessage[]> => {
    const {data}: AxiosResponse<IMessage[]> = await baseApi.get<IMessage[]>('/communication', {
        params: {
            limit,
            offset
        }
    });
    return data;
};

export const getMessageCount = async (): Promise<IMessage[]> => {
    const {data}: AxiosResponse<IMessage[]> = await baseApi.get<IMessage[]>('/communication', {
        params: {
            mode: 'count'
        }
    });
    return data;
};

export const getCandidateFromMsgByIdApi = async (communicationId: string): Promise<ICandidate> => {
    try {
        const {data}: { data: ICandidate } = await baseApi.get<ICandidate>(`/communication/${communicationId}`);
        return data;
    } catch (error) {
        console.error('Error fetching candidate:', error);
        throw error;
    }
};

export const getCandidateMessagesByComId = async (communication_id: number): Promise<IMessageBuble[]> => {
    try {
        const params = {communication_id};
        const {data}: { data: IMessageBuble[] } = await baseApi.get<IMessageBuble[]>(`/message`, {params});
        return data;
    } catch (error) {
        console.error('Error fetching candidate:', error);
        throw error;
    }
};

export const sendMessageApi = async (messageData: {
    message: string;
    type: string;
    client_id: number;
    candidate_id: number;
    job_demand_id: number;
    communication_id: number;
    contact_data: string;
}): Promise<IMessageBuble> => {
    try {
        const {data}: { data: IMessageBuble } = await baseApi.post<IMessageBuble>('/message', messageData);
        return data;
    } catch (error) {
        console.error('Error sending message:', error);
        throw error;
    }
};

export const getFinancePlanUrl = async (planKey: string): Promise<string> => {
    try {
        const authToken = getToken();
        const params = {
            id: authToken,
            product: planKey,
        };

        const response = await billingApi.get<{
            url: string
        }>('/checkout_session', {params});
        return response.request.responseURL
    } catch (error) {
        console.error('Error getting finance plan URL:', error);
        throw error;
    }
};

export const getMagicLink = async (email: string): Promise<{
    url: string
}> => {
    try {
        const params = {email};
        const {data}: { data: { url: string } } = await privacyApi.get<{
            url: string
        }>('/auth/request-magic-link', {params});
        return data;
    } catch (error) {
        console.error('Error changing plan:', error);
        throw error;
    }
};

export const getSearches = async (): Promise<ISearchResult> => {
    const {data}: { data: ISearchResult } = await baseApi.get('/job_search');
    return data;
};

export const getSearchById = async (id: number): Promise<ISearchResult> => {
    const {data}: { data: ISearchResult } = await baseApi.get(`/job_search/${id}`);
    return data;
};

export const addSearch = async (searchParam: Partial<IJobDemand>): Promise<IJobDetails> => {
    const {data}: { data: IJobDetails } = await baseApi.post<IJobDetails>(`/job_search`, searchParam);
    return data;
};

export const updateSearchById = async (id: number, updatedData: Partial<IJobDetails>): Promise<IJobDetails> => {
    const {data}: { data: IJobDetails } = await baseApi.patch<IJobDetails>(`/job_search/${id}`, updatedData);
    return data;
};

export const getCompanySize = async (): Promise<CompanySizeType> => {
    const {data}: { data: CompanySizeType } = await baseApi.get<CompanySizeType>("/company_size");
    return data;
};

export const getSectors = async () => {
    const {data} = await baseApi.get<SectorType[]>('/sector');
    return data;
};

export const getSubsectors = async (sector_id: string) => {
    const {data} = await baseApi.get<SectorType[]>('/subsector', {params: {sector_id}});
    return data;
};

export const getExperienceYears = async () => {
    const {data} = await baseApi.get<JobExpType[]>('/experience_years');
    return data;
};
export const getJobExperience = async () => {
    const {data} = await baseApi.get<JobExpType[]>('/employer_years');
    return data;
};
export const getEducationLevels = async () => {
    const {data} = await baseApi.get<JobExpType[]>('/education_level');
    return data;
};
export const getWorkLoadOptions = async () => {
    const {data} = await baseApi.get<JobExpType[]>('/work_load_candidate');
    return data;
};
export const updateCandidate = async ({id, body}: { id: number, body: DeepPartialCandidateProfile }) => {
    const {data} = await baseApi.put(`/candidate/${id}`, body);
    return data;
};

export const getCandidateMatchingPositions = async () => {
    const {data} = await baseApi.get<MatchingPositionsType[]>('/candidate_matching_positions');
    return data;
};

export const getCandidateMatchingPotJobs = async () => {
    const {data} = await baseApi.get<DesJobType[]>('/candidate_matching_potential_jobs');
    return data;
};

export const getRadiuses = async () => {
    const {data} = await baseApi.get<Radius[]>('/radius');
    return data;
};

export const modifyCandidateMatchingPosition = async ({candidateId, position_id, matching_used}: {
        candidateId: number,
        position_id: number,
        matching_used: boolean
    }) => {
        const {data} = await baseApi.put<JobExpType[]>(`/candidate/${candidateId}`, {
                    candidate_matching_positions: [{
                        id: position_id,
                        matching_used
                    }]
                }
            )
        ;
        return data;
    }
;
export const modifyCandExcludePosSurvey = async (body: {
    users_id: number,
    candidateId: number,
    positions_id: number,
    response: number,
    candidate_matching_position_id: number
}) => {
    const {data} = await baseApi.post<JobExpType[]>(`/exclude_position_survey`, body);
    return data;
};
export const modifyCandDeactivateSurvey = async (body: {
    "users_id": number,
    "candidate_id": number,
    "response": number
}) => {
    const {data} = await baseApi.post<JobExpType[]>(`/deactivate_survey`, body);
    return data;
};


export const addNpsRecord = async (body: {
    "users_id": number,
    "candidate_id": number,
    "points": number
}) => {
    const {data} = await baseApi.post<JobExpType[]>(`/nps_candidate`, body);
    return data;
};

export const getAlertConfigs = async (): Promise<IAlertConfig> => {
    const {data}: { data: IAlertConfig } = await baseApi.get('/alert_config');
    return data;
};

export const deleteUser = async (users_id: number): Promise<IAlertConfig> => {
    const {data}: { data: IAlertConfig } = await baseApi.delete(`/users/${users_id}`);
    return data;
};

export const addCourseToCandidate = async (courseDetail: { course_id: string; course_name: string }) => {
    const {data} = await baseApi.post('/usercourses', courseDetail);
    return data;
};

export const getUserCourses = async (): Promise<IAlertConfig> => {
    const {data}: { data: IAlertConfig } = await baseApi.get('/usercourses/1');
    return data;
};
export const getAvailableCourses = async (): Promise<ICourse[]> => {
    const {data}: { data: ICourse[] } = await coursesApi.get('/candidate_courses');
    return data;
};

export const gerCurrentCourse = async (courseID: string): Promise<ICourse> => {
    const {data}: { data: ICourse } = await coursesApi.get(`/courses/${courseID}`);
    return data;
};
