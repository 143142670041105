import {useContext, useEffect, useState} from "react";
import { AuthContext, SignInCredentials, SignUpCredentials } from "../../../context/AuthContext.tsx";
import { useStyles } from "./useLoginStyles.ts";
import { Grid } from "antd";
import { AxiosError } from "axios";

const { useBreakpoint } = Grid;

export const useLogin = () => {
    const[hasRedirect, setHasRedirect] = useState(true)
    const { error: authError, signIn } = useContext(AuthContext);
    const screens = useBreakpoint();
    const styles: { [key: string]: React.CSSProperties } = useStyles(screens);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const storedTarget = localStorage.getItem('appRedirectPath');
        if(storedTarget){
            setHasRedirect(true)
        }else {
            setTimeout(()=>setHasRedirect(false), 300)
        }
    }, [])

    const onSubmit = async (values: SignInCredentials | SignUpCredentials) => {
        localStorage.removeItem("appRedirectPath")
        try {
            setError(null);
            await signIn(values);
            // Don't navigate here - let AuthContext handle the redirect
            // The navigation in AuthContext will check for appPredefinedRedirectPath
        } catch (err) {
            if (err instanceof AxiosError && err.response?.status === 401) {
                setError("Ungültige E-Mail oder ungültiges Passwort. Bitte versuchen Sie es erneut.");
            } else {
                setError("Ein Fehler ist aufgetreten. Versuchen Sie es später noch einmal.");
            }
        }
    };

    return {
        onSubmit,
        styles,
        error,
        authError,
        hasRedirect
    };
};
