import {ReactNode, Suspense, useContext, useEffect} from 'react'
import {Navigate, useLocation} from 'react-router-dom'
import {ErrorBoundary} from 'react-error-boundary'
import {AuthContext} from "../context/AuthContext.tsx";
import {Loader} from "../components/Loader.tsx";
import {ErrorState} from "../components/ErrorState.tsx";
import {LayoutWrapper} from "../components/LayoutWrapper.tsx";
import {menuItems} from "../components/menuItems.tsx";

type Props = {
    permissions?: string[]
    roles?: string[]
    redirectTo?: string
    children: ReactNode
}

export const PrivateRoute = (props: Props) => {
    const {redirectTo = '/login', children} = props
    const location = useLocation()
    const {isAuthenticated, user, loadingUserData, signOut} = useContext(AuthContext)

    const hasAllPermissions = !!user

    useEffect(() => {
        // Save the current path when component mounts or path changes
        if (location.pathname !== '/') {
            localStorage.setItem('appPredefinedRedirectPath', location.pathname + location.search);
        }
    }, [location.pathname, location.search]);

    if (loadingUserData) {
        return <Loader/>
    }

    if (!isAuthenticated) {
        const redirectPath = location.pathname + location.search;
        localStorage.setItem('appPredefinedRedirectPath', redirectPath);

        // Include the redirect path in the URL query parameters
        const loginPath = `${redirectTo}?from=${encodeURIComponent(redirectPath)}`;

        return <Navigate to={loginPath} state={{ from: location }} replace />
    }

    if (!hasAllPermissions) {
        return <Navigate to="/"/>
    }

    // The redirect path is now managed entirely by AuthContext.tsx
    // We don't need to clear it here - that would interfere with the login process

    return (
        <ErrorBoundary
            fallback={<ErrorState/>}
        >
            <Suspense fallback={<Loader/>}>
                <LayoutWrapper menuItems={menuItems} signOut={signOut}>{children}</LayoutWrapper>
            </Suspense>
        </ErrorBoundary>
    )
}
