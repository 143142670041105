/* eslint-disable */
// @ts-nocheck
import {useParams} from "react-router-dom";
import styled from "styled-components";
import type {TabsProps} from 'antd';
import {Button, message, notification, Spin, Tabs} from 'antd';
import {addCourseToCandidate, gerCurrentCourse, getUserCourses} from "../../services/api.ts";
import {useMutation, useQuery, useQueryClient, UseQueryResult} from "@tanstack/react-query";
import {UserCourses} from "../courses/types.ts";
import {useEffect} from "react";
import {ICourse} from "../../services/types.ts";
import {format} from "date-fns";
import { PageTitle } from "../../components/PageTitle.tsx";
import {de} from "date-fns/locale";

const baseUrl = import.meta.env.VITE_COURSES_BASE_URL;


export const CourseDetails = () => {
    const {key = ""} = useParams<{ key: string }>();
    const queryClient = useQueryClient();
    const {data: userCourses}: UseQueryResult<UserCourses[], Error> = useQuery({
        queryKey: ['userCourses'],
        queryFn: getUserCourses,
    });

    const {data: currentCourse, isLoading}: UseQueryResult<ICourse, Error> = useQuery({
        queryKey: ['course', key],
        queryFn: () => gerCurrentCourse(key),
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])


    const addCourseMutation = useMutation({
        mutationFn: () => addCourseToCandidate({
            course_id: currentCourse.id,
            course_name: currentCourse.title
        }),
        onSuccess: () => {
            notification.success({message: "Wir haben Ihre Anfrage gespeichert.", duration: 2});
            queryClient.invalidateQueries({queryKey: ['userCourses']});
        },
        onError: (error) => {
            console.error('Error saving template:', error);
            message.error('Failed to save template');
        },
    });

    if (isLoading || !currentCourse?.id) {
        return (
            <div style={{textAlign: "center", marginTop: "50px"}}>
                <Spin size="large"/>
            </div>
        );
    }

    if (!currentCourse?.id && !isLoading) {
        return <NotFound>Course not found.</NotFound>;
    }

    const isCourseAdded = userCourses?.find((el: UserCourses) => el.course_id === currentCourse?.id);

    const addCourseToCandidateHandler = async () => {
        addCourseMutation.mutate();
    }


    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Kursinhalt',
            children: (
                <DetailsWrapper>
                    <div dangerouslySetInnerHTML={{__html: currentCourse.course_details}}/>
                </DetailsWrapper>
            )
        },
        {
            key: '2',
            label: 'Kursleiter',
            children: (
                <LecturerWrapper>
                    <img width={150} height={150} src={`${baseUrl.split('/api')[0]}/${currentCourse.lecturer_avatar}`}
                         alt={currentCourse.lecturer}/>
                    <LecturerName>{currentCourse.lecturer}</LecturerName>
                    <LecturerDescription>{currentCourse.lecturer_bio}</LecturerDescription>
                </LecturerWrapper>
            ),
        }
    ];


    return (<>
        <PageTitle>Course details</PageTitle>
        <Wrapper>
            <Info>
                <ImageAndInfo>
                    <img src={`${baseUrl.split('/api')[0]}/${currentCourse.course_image}`} alt={currentCourse.title}/>
                </ImageAndInfo>
                <RightSection>
                    <CourseDescription>{currentCourse.title}</CourseDescription>
                    <hr/>
                    <SecondaryTitle style={{marginBottom: 0}}>{currentCourse.secondary_title}</SecondaryTitle>

                    <CourseSectionWrapper>
                        <CoourseDateTime>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "2px",
                                alignItems: "center"
                            }}>
                                {currentCourse.status === "planned" ?
                                    <p style={{margin: '29px 0 0 0'}}>In Kürze verfügbar</p>
                                    :
                                    <>
                                        <span style={{fontSize: "16px"}}>{format(new Date(currentCourse.start_date), 'EEEE', {locale: de})}, {format(new Date(currentCourse.start_date), 'dd. MMMM', {locale: de})}</span>
                                        <span style={{fontSize: "14px", fontWeight: "bold"}}>{format(new Date(currentCourse.start_date), 'HH:mm')} Uhr</span>
                                    </>
                                }
                            </div>
                            <div>
                                {currentCourse.status === "planned" &&
                                    <p style={{margin: 0}}>Sobald der Kurs verfügbar ist, bekommen Sie von uns eine E-Mail.
                                    </p>
                                }

                                {isCourseAdded ? <p style={{margin: 0}}>Bereits registriert</p>  :<Button type="primary" style={{marginBottom: 20, marginTop: 20, width: 150}}
                                        onClick={addCourseToCandidateHandler}
                                        loading={addCourseMutation.isPending}>
                                    Buchen
                                </Button>}
                            </div>
                        </CoourseDateTime>
                        <CourseLecturer>
                            <img style={{borderRadius: "50%", width: 55, height: 55}}
                                 src={`${baseUrl.split('/api')[0]}/${currentCourse.lecturer_avatar}`} alt=""/>
                            <h3>Kursleitung: {currentCourse.lecturer}</h3>
                        </CourseLecturer>
                    </CourseSectionWrapper>

                </RightSection>
            </Info>

            <DetailsInfo>
                <Tabs defaultActiveKey="1" items={items} size="large" option={{size: 'large'}}/>
            </DetailsInfo>

            <FixedButtons>
                {isCourseAdded ? <AlreadyRegistered style={{margin: 0}}>Bereits registriert</AlreadyRegistered> :
                    <Button type="primary" style={{width: 200}} loading={addCourseMutation.isPending}
                            onClick={addCourseToCandidateHandler}>Buchen</Button>}
                <p style={{ marginTop: 'auto', marginBottom: 'auto' }}>Fragen? Kontaktieren Sie uns unter info@silvertalent.com</p>
            </FixedButtons>
        </Wrapper>
    </>


    );
};

// Styled Components
const Wrapper = styled.div`
    padding-bottom: 32px;
    align-items: center;
    .ant-tabs-nav-operations{
        display: none !important;
    }
`;

const FixedButtons = styled.div`
    bottom: 0;
    display: flex;
    padding: 10px 20px;
    box-shadow: none;
    gap: 40px;
`;

const LecturerWrapper = styled.div`
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
        border-radius: 50%;
    }
`;

const DetailsWrapper = styled.div`
    padding: 20px;
`;

const Info = styled.div`
    display: flex;
    align-items: flex-start;
    
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;

const DetailsInfo = styled.div`
    margin-top: 20px;

    .ant-tabs-nav-wrap {
        justify-content: center;
        align-items: flex-start;
    }


    .ant-tabs-tab-btn {
        color: black;
        padding-right: 250px;
        padding-left: 250px;
    }
    @media (max-width: 1400px) {
        .ant-tabs-tab-btn {
            padding-right: 100px;
            padding-left: 100px;
        }
    }
    @media (max-width: 1024px) {
        .ant-tabs-tab-btn {
            padding-right: 60px;
            padding-left: 60px;
        }
    }
    
    @media (max-width: 768px) {
        .ant-tabs-tab-btn {
            padding-right: 30px;
            padding-left: 30px;
        }
    }
`;


const ImageAndInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin: 10px;
    width: 45%;
    
    @media (max-width: 768px) {
        width: 100%;
    }
    img {
        width: 100%;
        object-fit: cover;
        height: 350px;
        border-bottom-right-radius: 60px;
        border-top-left-radius: 60px;
        
        @media (max-width: 768px) {
            height: 200px;
        }
    }

    h1, p {
        margin: 0;
    }
`;


const RightSection = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 20px;
    margin-left: 60px;
    margin-right: 60px;
    @media (max-width: 768px) {
        margin-left: 0;
        margin-right: 0;
    }
    hr {
        width: 70%;
        margin: 10px auto;
        border: 2px solid grey;
    }
`;

const CourseLecturer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-top: 35px;
`

const NotFound = styled.div`
    font-size: 20px;
    font-weight: bold;
`

const LecturerName = styled.h1`
    font-weight: bold;
`
const CourseSectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`
const CourseDescription = styled.p`
    margin: 0;
    font-size: 28px;
    font-weight: bold;
    @media (max-width: 768px) {
        font-size: 20px;
    }
`

const SecondaryTitle = styled.div`
    font-size: 28px;
    @media (max-width: 768px) {
        font-size: 20px;
    }
`
const LecturerDescription = styled.p`
    margin: 0;
    font-size: 18px;
    width: 70%;
    padding-bottom: 16px;
`
const CoourseDateTime = styled.div`
    font-size: 12px;
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

const AlreadyRegistered = styled.p`
    font-size: 22px;
    color: #00b1bb;
    margin: 16px 0;
`

