import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Input} from "antd";
import {ModalComponent} from "../pages/jobPool/ModalComponent.tsx";
import {IPosition, IPositions} from "../services/types.ts";
import {getSearchWhat} from "../services/api.ts";
import styled from "styled-components";
import {colors} from "../util/colors.ts";

interface IProfessionModal {
    isModalOpen: boolean;
    onClose: () => void;
    initialValue: IPosition | null;
    handleSaveProf: (selectedOption: IPosition | null) => void;
}

export const ProfessionModal = ({isModalOpen, onClose, handleSaveProf, initialValue}: IProfessionModal) => {
    const [whatSearch, setWhatSearch] = useState('');
    const [whatOptions, setWhatOptions] = useState<IPositions>([]);
    const [whatSelectedOption, setWhatSelectedOption] = useState<IPosition | null>(null);

    const getWhatOptions = async () => {
        try {
            const data: IPositions = await getSearchWhat();
            setWhatOptions(data);
        } catch (error) {
            console.error('Error fetching what options:', error);
        }

    }

    useEffect(() => {
        setWhatSelectedOption(initialValue as IPosition)
        getWhatOptions()
    }, [initialValue])

    const toggleWhatOption = useCallback((selectedOption: IPosition) => {
        setWhatSelectedOption(selectedOption);
    }, []);

    const filteredWhatOptions = useMemo(() => {
        return whatOptions.filter(option =>
            option.label.toLowerCase().includes(whatSearch.toLowerCase()) ||
            (option.matching_keywords && option.matching_keywords.toLowerCase().includes(whatSearch.toLowerCase()))
        );
    }, [whatOptions, whatSearch]);

    const onWhatSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setWhatSearch(e.target.value);
    }, []);

    const handleSaveProfession = () => {
        handleSaveProf(whatSelectedOption);
        onModalClose()
    }

    const onModalClose = () => {
        onClose()
        setWhatSelectedOption(null)
        setWhatSearch('')
    }


    return <ModalComponent
        title="Was suchen Sie?"
        open={isModalOpen}
        onClose={onModalClose}
        onSave={handleSaveProfession}
        footerValue={<div style={{textAlign: 'left'}}>{whatSelectedOption?.label || initialValue?.label || "Alle Positionen, kein Filter"}</div>}
    >
        <Input
            size="large"
            placeholder="Suche Berufsbezeichnung oder Tätigkeit..."
            value={whatSearch}
            onChange={onWhatSearch}
        />
        <WhatOptionsWrapper>
            {filteredWhatOptions.map((option) => {
                return (
                    <WhatOption
                        key={option.id}
                        onClick={() => toggleWhatOption(option)}
                        isSelectedOpt={whatSelectedOption?.id === option.id}
                    >
                        {option.label}
                    </WhatOption>
                )
            })}
        </WhatOptionsWrapper>
    </ModalComponent>
}

const WhatOptionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 70vh;
    overflow-y: auto;
    margin-top: 16px;
    @media (max-width: 767px) {
        height: 40vh;
    }
`;

export const WhatOption = styled.div<{ isSelectedOpt: boolean }>`
    display: flex;
    padding: 16px;
    cursor: pointer;
    font-weight: ${props => props.isSelectedOpt ? 'bold' : 'normal'};
    background: ${props => props.isSelectedOpt ? colors.lightBlue : 'white'};

    &:hover {
        transition: all 0.3s ease;
        background-color: ${colors.lightBlue};
        border-radius: 4px;
    }
`;
