import {Link} from "react-router-dom";
// import styled from "styled-components";

export interface MenuItem {
    key: string;
    label: JSX.Element;
}

// const NewMenuItemText = styled.span`
//     position: absolute;
//     right: -14px;
//     top: -33px;
//     color: #d700ff;
//     font-size: 11px;
// `;


export const menuItems: MenuItem[] = [
    {
        key: "pool",
        label: <Link to="/">Stellenangebote</Link>,
    },
    {
        key: "messages",
        label: <Link to="/messages">Nachrichten</Link>,
    },
    //
    // {
    //     key: "courses",
    //     label: <Link to="/courses" style={{position: 'relative'}}>Kurse<NewMenuItemText>Neu</NewMenuItemText></Link>,
    // },

    {
        key: "profile",
        label: <Link to="/profile">Mein Profil</Link>,
    },
    {
        key: "account",
        label: <Link to="/account">Mein Konto</Link>,
    }
];


